@import "./config";

.navbar {
    height: auto;
    background-color: $btn-seen;
    // width: calc(100vw + 250px);
    // border: 2px solid green;
}

.navbar-new {
    height: auto;
    background-color: $btn-seen;
    width: 100%
}

.navbar-open {
    width: calc(100vw + 12%) !important;
    height: auto;
    background-color: $btn-seen;
    // border: 2px solid red;
}

// .navbar-icons :hover {
//     color: $bg-timesales;
// }
.navbar-icons :focus, .navbar-icons :target .navbar-icons:focus-within , .navbar-icons :visited{
    color: $add-on !important;
}
.navbar-icons :active{
    color: $add-on !important;
}

nav {
    padding: 10px;
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;

    .top,
    .bottom {
        display: flex;
    }

    .navbar-icons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 5px 20px;
        // padding-left: -5px;

        .nav-icon {
            width: 30px;
            height: 30px;
            color: $secondary-color;
            text-align: center;

        }
    }

    label {
        margin: 0px 10px;
        color: $secondary-color;
    }
}

.btn-bar {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: $table-num;
    color: white;
}

@media screen and (max-width:989px) {

    .navbar-icons {
        margin: 5px 10px !important;

        label {
            margin: 0px 0px !important;
            color: $secondary-color;
        }
    }
}

@media screen and (min-width:986px) {
    .navbar-open {
        width: 100% !important;
        // border: 2px solid yellow;
    }
}

@media screen and (max-width:768px) {
    .navbar {
        width: calc(100vw + 250px);
    }

    nav {
        flex-direction: column;

        .navbar-icons {
            margin: 5px 0px;

        }
    }

    .nav-icon {
        width: 20px !important;
        height: 20px !important;
    }
}

@media screen and (max-width:465px) {
    nav {
        label {
            margin: 0px 4px;
        }
    }

    .nav-icon {
        width: 15px !important;
        height: 15x !important;
    }
}