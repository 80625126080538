    .performance-chart {
        min-height: 50vh !important;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 20px;
    }

    @media screen and (min-width:768px) {
        .performance-chart-stats {
            width: 650px;
            height: 450px;
        }
    }

    @media screen and (max-width:990px) {
        .performance-chart {
            display: flex;
            flex-direction: column;
        }
    }

    .search-select-year-outlet {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 20px 0px;

        .select-outlet-year {
            margin: 10px 0px;
        }
    }