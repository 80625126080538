
.img-logo{
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo{
    width: 100px;
    height: 100px;
  }
}

.bg-login {
  height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;

  .bg-form-img {
    background: url("../../public/bg.jpeg");
    height: 100%;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    filter: blur(3px);
  }

  form {
    height: 550px;
    width: 420px;
    backdrop-filter: blur(55px);
    background-color: rgba(255, 255, 255, 0.774);
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    padding: 50px 35px;
    font-family: "Poppins", sans-serif;
    color: black;
    letter-spacing: 0.5px;
    outline: none;
    border: none;
  }
  
  // form * {
  //   font-family: "Poppins", sans-serif;
  //   color: black;
  //   letter-spacing: 0.5px;
  //   outline: none;
  //   border: none;
  // }

  form h5 {
    // font-size: 40px;
    font-weight: 700;
    line-height: 42px;
    text-align: center;
  }

  label {
    display: block;
    margin-top: 30px;
    font-size: 16px;
    font-weight: 500;
  }

  .username, .password{
    display: block;
    height: 50px;
    width: 100%;
    background-color: rgba(238, 231, 231, 0.644);
    border-radius: 3px;
    padding: 0 10px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: 300;
  }

  ::placeholder {
    color: #e6dede00;
  }

  input:hover {
    outline: 2px solid #0001;
  }

  .btn-login {
    margin-top: 50px;
    width: 100%;
    background-color: black;
    color: white;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
  }

}

.error{
  color: red;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .bg-form-img {
    background: url("../../public/responsivebg.jpeg") !important;
  }
}
@media only screen and (max-width: 426px){
  form {
    height: auto !important;
    width: calc(100% - 30px) !important;
  }
}