.modal-content {
    font-size: 16px;

    .modal-header {
        flex-direction: column !important;
    }

    .modal-bill-info-table {
        .modal-detail {
            display: flex;
            flex-direction: column;

            h6 {
                text-transform: uppercase;
                text-align: center;
            }

            .report-info {
                display: flex;
                justify-content: space-between;
            }

            span {
                padding-left: 05px;
            }
        }

        display: flex;
        flex-direction: column;
        gap: 20px;

        tbody {
            display: flex;
            justify-content: center;
            width: calc(100% - 20px);
            height: 150px;
            overflow-y: auto;
            .position-sticky{
                position: sticky;
                top: 0;
                background-color: white;
              }
        }

        .bill-info-details {
            // height: auto;
            text-align: center;

            td,
            th {
                border: 1px solid black
            }
        }

        .modal-amount {
            float: right;

            span {
                font-weight: 600;
                padding-right: 5px;
            }
        }
    }
}