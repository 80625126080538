@import "./config";

.css-1wvake5.ps-collapsed {
    min-width: 0px !important;
    width: 0px !important;
}

.offcanvas-header {
    background-color: $card-header;
    color: $secondary-color;
}

.sidebar {
    position: relative;

    .dine-tabs-paymemnt-status{
        margin: 0px 8px;
    }
    .bill-no ,.dine-tabs-paymemnt-status{
        display: flex;
        flex-direction: column;

        span {
            font-size: 18px;
            font-weight: 600;
        }
    }

    .btn-sidebar {
        width: 40px;
        height: 40px;
        border: 1px solid $btn-search;
        background-color: $btn-search;
        color: white;
        padding: 10px;
        border-radius: 50%;
        margin-right: 5px;

        // position: absolute;
        // top: 2%;
        // left:250px;
        &:hover {
            background-color: $btn-search-hover;
        }
    }

    .dine-tabs-list {
        margin: 25px 10px;
        display: flex;
        flex-direction: column;
        // align-items: center;

        .hr-line {
            border: 1px dotted black;
            margin: 10px 20px;
        }

        .total-info {
            padding: 10px 0px;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            // align-items: center;

            label {
                font-size: 18px;
            }

            span {
                font-weight: 600;
            }

            .info {
                padding: 5px 0px;
                // margin-left: 10px;
            }
        }

    }
}